import Typography from '@mui/material/Typography';

export interface LabelProps {
  color: string;
  label: string;
}

export const Label = ({ label, color }: LabelProps): JSX.Element => {
  return (
    <Typography sx={{ color }} variant="body2">
      {label}
    </Typography>
  );
};
