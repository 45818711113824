import Box from '@mui/material/Box';
import { Dot } from './Dot';
import { Label } from './Label';

interface LabelledDotProps {
  label: string;
  color: string;
}

export const LabelledDot = ({ ...rest }: LabelledDotProps): JSX.Element => {
  return (
    <Box display="flex" alignItems="center">
      <Dot {...rest} />
      <Box paddingLeft={1}>
        <Label {...rest} />
      </Box>
    </Box>
  );
};
