import Stack from '@mui/material/Stack';
import { Typography } from '@automata/ui/';
import Box from '@mui/material/Box';
import { CreateButton } from 'components/CreateButton';
import { MouseEventHandler, ReactElement, useRef } from 'react';

interface HeaderProps {
  pageName: string;
  cypressData: string;
  onConfirmAction?: MouseEventHandler<HTMLButtonElement>;
  popover?: (anochorElement: HTMLElement) => ReactElement;
}

export const ListScreensHeader = ({
  pageName,
  cypressData,
  onConfirmAction,
  popover,
}: HeaderProps): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h6">{pageName}</Typography>
        {onConfirmAction && (
          <CreateButton
            ref={buttonRef}
            cypressData={cypressData}
            onConfirmAction={onConfirmAction}
          />
        )}
      </Stack>
      {popover && buttonRef.current && popover(buttonRef.current)}
    </Box>
  );
};
