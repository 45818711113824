import {
  DeviceData,
  DeviceState,
  DeviceStateStatus,
  Workflow,
} from '@automata/api/apiSchemas';

export enum WorkflowStatusEnum {
  BUILTIN = 'built_in',
  INSYNC = 'in_sync',
  NOTINSYNC = 'not_in_sync',
  REMOVED = 'removed',
  ERROR = 'unknown',
  NOWORKFLOW = 'no_workflow',
}

export enum TypeEnum {
  NEUTRAL = 'NEUTRAL',
  OK = 'OK',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  STANDOUT = 'STANDOUT',
}

export type StatusAttributes = {
  label: string;
  type: TypeEnum;
};

export type AttributesMap<T> = Map<T, StatusAttributes>;

export const deviceStatusAttributes: AttributesMap<DeviceState['status']> =
  new Map([
    ['init', { label: 'Initialising', type: TypeEnum.OK }],
    ['loading', { label: 'Loading', type: TypeEnum.OK }],
    ['uncalibrated', { label: 'Uncalibrated', type: TypeEnum.WARNING }],
    ['ready', { label: 'Ready', type: TypeEnum.OK }],
    ['running', { label: 'Running', type: TypeEnum.OK }],
    ['paused', { label: 'Paused', type: TypeEnum.WARNING }],
    ['stopping', { label: 'Stopping', type: TypeEnum.WARNING }],
    ['resetting', { label: 'Resetting', type: TypeEnum.WARNING }],
    ['error', { label: 'Error', type: TypeEnum.ERROR }],
    ['action_required', { label: 'Action Required', type: TypeEnum.STANDOUT }],
    ['emergency_stop', { label: 'Emergency Stop', type: TypeEnum.ERROR }],
    ['aborted', { label: 'Aborted', type: TypeEnum.WARNING }],
  ]);

export const workflowStatusAttributes: AttributesMap<WorkflowStatusEnum> =
  new Map([
    [WorkflowStatusEnum.BUILTIN, { label: 'Built-in', type: TypeEnum.OK }],
    [WorkflowStatusEnum.ERROR, { label: 'Error', type: TypeEnum.ERROR }],
    [WorkflowStatusEnum.INSYNC, { label: 'In sync', type: TypeEnum.SUCCESS }],
    [
      WorkflowStatusEnum.NOTINSYNC,
      { label: 'Out of sync', type: TypeEnum.WARNING },
    ],
    [WorkflowStatusEnum.REMOVED, { label: 'Removed', type: TypeEnum.WARNING }],
    [
      WorkflowStatusEnum.NOWORKFLOW,
      { label: 'No workflow', type: TypeEnum.NEUTRAL },
    ],
  ]);

export const deviceOnlineAttributes: AttributesMap<'online' | 'offline'> =
  new Map([
    ['online', { label: 'Online', type: TypeEnum.SUCCESS }],
    ['offline', { label: 'Offline', type: TypeEnum.ERROR }],
  ]);

export const deviceReadyForDeployment: AttributesMap<'yes' | 'no'> = new Map([
  ['yes', { label: 'Ready for deployment', type: TypeEnum.SUCCESS }],
  ['no', { label: 'Not ready for deployment', type: TypeEnum.NEUTRAL }],
]);

export const checkDeploymentStatus = (
  selectedWorkflow: Workflow | undefined,
  device: DeviceData
): StatusAttributes | undefined => {
  const { workflow } = device;

  if (!workflow) {
    return workflowStatusAttributes.get(WorkflowStatusEnum.NOWORKFLOW);
  }

  if (!workflow?.id || workflow?.id === '') {
    return workflowStatusAttributes.get(WorkflowStatusEnum.ERROR);
  }

  if (workflow?.checksum === '<builtin>') {
    return workflowStatusAttributes.get(WorkflowStatusEnum.BUILTIN);
  }

  if (selectedWorkflow?.checksum === workflow.checksum) {
    return workflowStatusAttributes.get(WorkflowStatusEnum.INSYNC);
  }

  return workflowStatusAttributes.get(WorkflowStatusEnum.NOTINSYNC);
};

export const isDeviceRunning = (status: DeviceState['status']): boolean => {
  const runningStates: Partial<DeviceStateStatus>[] = [
    'running',
    'paused',
    'stopping',
    'resetting',
  ];

  return runningStates.includes(status);
};
