import Box from '@mui/material/Box';

const styles = {
  indicator: {
    width: 10,
    height: 10,
    borderRadius: 100,
  },
};

export interface DotProps {
  color: string;
}

export const Dot = ({ color }: DotProps): JSX.Element => {
  return <Box sx={{ ...styles.indicator, backgroundColor: color }} />;
};
