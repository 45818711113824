import Chip from '@mui/material/Chip';

interface PillProps {
  color?: string;
  label: string | undefined;
}

export const Pill = ({ color, label }: PillProps): JSX.Element => {
  return (
    <Chip
      sx={(theme) => {
        return {
          color: theme.palette.getContrastText(color ?? 'palette.common.white'),
          backgroundColor: color ?? 'palette.grey[200]',
          fontWeight: 400,
        };
      }}
      label={label}
      size="small"
    ></Chip>
  );
};
