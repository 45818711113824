import { useMemo } from 'react';
import { Pill } from 'components/Pill';
import { StatusAttributes, TypeEnum } from 'utils/status';
import { defaultTheme as theme } from '@automata/mui-theme';
import { Dot } from './variants/Dot';
import { LabelledDot } from './variants/LabelledDot';
import { Label } from './variants/Label';

export enum VariantsEnum {
  DEFAULT = 'default',
  LABEL = 'label',
  DOT = 'dot',
  LABELLED_DOT = 'labelledDot',
}

export type StateIndicatorProps = {
  attrs?: StatusAttributes;
  variant?: VariantsEnum;
};

export const StateIndicator = ({
  attrs,
  variant = VariantsEnum.DEFAULT,
}: StateIndicatorProps): JSX.Element | null => {
  const statusLabel = useMemo(() => {
    return attrs?.label ?? 'Unknown';
  }, [attrs?.label]);

  const statusColor = useMemo(() => {
    const defaultColor = theme.palette.grey[700];

    if (attrs?.type === undefined) {
      return defaultColor;
    }

    const colorMap = new Map([
      [TypeEnum.OK, theme.palette.success.main],
      [TypeEnum.ERROR, theme.palette.error.light],
      [TypeEnum.NEUTRAL, defaultColor],
      [TypeEnum.STANDOUT, theme.palette.info.main],
      [TypeEnum.SUCCESS, theme.palette.success.main],
      [TypeEnum.WARNING, theme.palette.warning.main],
    ]);

    return colorMap.get(attrs.type) ?? defaultColor;
  }, [attrs?.type]);

  switch (variant) {
    case VariantsEnum.LABEL:
      return <Label label={statusLabel} color={statusColor} />;
    case VariantsEnum.DOT:
      return <Dot color={statusColor} />;
    case VariantsEnum.LABELLED_DOT:
      return <LabelledDot label={statusLabel} color={statusColor} />;
    default:
      return <Pill label={statusLabel} color={statusColor} />;
  }
};
