import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { buildGroupTree, getDevicePath } from './utils';
import { deviceOnlineAttributes, deviceStatusAttributes } from 'utils/status';
import { StateIndicator, VariantsEnum } from 'components/StateIndicator';
import {
  dateAccessedColumn,
  getMenuColumn,
  createColumns,
  createRows,
  _accessColumnIds,
  getNameColumn,
} from 'components/ModelList/columns';
import { ResourceActions } from 'components/ResourceActions';
import { NotificationDeviceSwitch } from 'components/Notifications/NotificationDeviceSwitch';
import { ModelListProps } from 'components/ModelList';
import { Row } from 'components/ModelList/types';
import { RoutedGroup } from './types';
import { UIModelNames } from 'model/types';
import {
  useGetWorkspaceDeviceGroupsV2,
  useGetWorkspaceDevices,
} from '@automata/api/apiComponents';
import useUserV2 from 'hooks/useUserV2';

interface Props {
  setSelectedGroup: Dispatch<SetStateAction<RoutedGroup | undefined>>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setSelectedAction: Dispatch<SetStateAction<ResourceActions>>;
}

const menuOptions = [ResourceActions.EDIT, ResourceActions.DELETE];

// todo: error handling
const useModelListData = ({
  setSelectedGroup,
  setOpenModal,
  setSelectedAction,
}: Props): ModelListProps['data'] => {
  const { data: user, isLoading: isUserLoading } = useUserV2();

  const { data: devices, isLoading: isLoadingDevices } = useGetWorkspaceDevices(
    {
      pathParams: { workspaceID: user?.currentWorkspace.id ?? '' },
      queryParams: { count: 1000 },
    },
    {
      enabled: !!user?.currentWorkspace.id,
      refetchInterval: 10000,
    }
  );

  const { data: groups, isLoading: isLoadingGroups } =
    useGetWorkspaceDeviceGroupsV2(
      {
        pathParams: { workspaceID: user?.currentWorkspace.id ?? '' },
      },
      {
        enabled: !!user?.currentWorkspace.id,
      }
    );

  const routedGroups = useMemo(() => buildGroupTree(groups), [groups]);

  const loading = useMemo(
    () => isLoadingDevices || isLoadingGroups || isUserLoading,
    [isLoadingDevices, isLoadingGroups, isUserLoading]
  );

  const selectGroup = useCallback(
    (id: string) => {
      const groupToSelect = routedGroups?.get(id);
      if (groupToSelect) {
        setSelectedGroup(groupToSelect);
      }
    },
    [routedGroups, setSelectedGroup]
  );

  const handleSelectAction = useCallback(
    (action: ResourceActions) => {
      setSelectedAction(action);
      setOpenModal(true);
    },
    [setOpenModal, setSelectedAction]
  );

  const columns = createColumns((columnIds) => [
    getNameColumn({ hide: true }), // fixes default search functionality
    {
      field: columnIds.DEVICES_SERIAL,
      headerName: 'Serial',
      headerClassName: 'hideRightSeparator',
      hide: true,
      valueGetter: ({ value }: GridValueGetterParams) => {
        return value;
      },
      headerAlign: 'left',
      flex: 1,
    },
    {
      field: columnIds.DEVICES_ONLINE,
      headerName: 'Network Status',
      headerClassName: 'hideRightSeparator',
      filterable: false,
      renderCell: function getOnlineIndicator({ value }) {
        return value && <StateIndicator attrs={value} />;
      },
      width: 150,
    },
    {
      field: columnIds.DEVICES_STATUS,
      headerName: 'Operational Status',
      headerAlign: 'left',
      flex: 1,
      filterable: false,
      renderCell: function getStatusIndicator({ value }: GridRenderCellParams) {
        return value ? (
          <StateIndicator attrs={value} variant={VariantsEnum.LABEL} />
        ) : (
          'Group'
        );
      },
    },
    dateAccessedColumn,
    {
      field: columnIds.DEVICES_NOTIFICATIONS,
      headerName: 'Notifications',
      headerClassName: 'hideRightSeparator',
      filterable: false,
      renderCell: function notificationSwitch({ value }: GridRenderCellParams) {
        const userID = user?.userData?.user_id;
        return (
          value &&
          userID && (
            <NotificationDeviceSwitch deviceID={value} userID={userID} />
          )
        );
      },
      headerAlign: 'left',
      flex: 0.5,
    },
    getMenuColumn(
      { field: columnIds.DEVICES_MENU },
      {
        menuOptions,
        resourceType: UIModelNames.GROUP,
        selectResource: selectGroup,
        selectAction: handleSelectAction,
      }
    ),
  ]);

  const groupRows: Row[] = useMemo(
    () =>
      createRows((columnIds) =>
        Array.from(routedGroups.values()).map((group) => ({
          [columnIds.ID]: group.id,
          [columnIds.NAME]: group.name,
          [columnIds.DEVICES_MENU]: group.id,
          [columnIds.DEVICES_PATH]: group.path,
          [columnIds.TOGGLE_EXPANSION]: true,
        }))
      ),
    [routedGroups]
  );

  const deviceRows = useMemo(
    () =>
      createRows((columnIds) => {
        if (!devices) return [];

        return devices.devices.map((device) => ({
          [columnIds.ID]: encodeURI(device.serial),
          [columnIds.NAME]: device.name,
          [columnIds.DEVICES_SERIAL]: device.serial,
          [columnIds.DEVICES_ONLINE]: deviceOnlineAttributes.get(
            device.online ? 'online' : 'offline'
          ),
          [columnIds.DEVICES_STATUS]: deviceStatusAttributes.get(
            device.state.status
          ),
          [columnIds.DATE_ACCESSED]: device.last_access,
          [columnIds.DEVICES_NOTIFICATIONS]: device.id,
          [columnIds.DEVICES_PATH]: getDevicePath(device, routedGroups),
        }));
      }),
    [devices, routedGroups]
  );

  const rows = useMemo(
    () => [...groupRows, ...deviceRows],
    [deviceRows, groupRows]
  );

  const [groupingColDef] = useMemo(
    () =>
      createColumns((columnIds) => [
        {
          field: columnIds.NAME,
          headerName: 'Name',
          flex: 2,
          hideable: false,
          filterable: true,
          sortable: true,
          valueGetter: ({ row }) => row.name,
        },
      ]),
    []
  );

  return {
    columns,
    rows,
    treeData: true,
    getTreeDataPath: (props: Row) =>
      _accessColumnIds<string[]>(({ DEVICES_PATH }) => props[DEVICES_PATH]),
    groupingColDef,
    loading,
    disableSelectionOnClick: true,
  };
};

export default useModelListData;
